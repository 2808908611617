<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Create Webhook Key</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>

                        <v-card-text>
                            <p>A webhook key is used to receive push notifications from Unicorn Springs about accounts, users, orders, etc.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Create
                            </v-btn> -->
                        </v-card-text>

                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newKeyLabel" label="Label" hint="A name for this webhook key" ref="newKeyLabelInput" outlined dense></v-text-field>
                            <v-select v-model="newKeyCipherAlg" :items="cipherAlgChoices"  label="Cipher algorithm" hint="How to sign messages" ref="newKeyCipherAlgInput" outlined dense></v-select>
                            <!-- TODO: this should be a select list, with values depending on the selected cipher algorithm -->
                            <v-select v-model="newKeyLength" :items="keyLengthChoices" label="Key Length" hint="Measured in bits" ref="newKeyLengthInput" outlined dense></v-select>
                            <v-text-field v-model="newKeyNotBefore" label="Start date for using this key (optional)" hint="Key will not be used before this date" ref="newKeyNotBeforeInput" outlined dense></v-text-field>
                            <v-text-field v-model="newKeyNotAfter" label="End date for using this key (optional)" hint="Key will not be used after this date" ref="newKeyNotAfterInput" outlined dense></v-text-field>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="green white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <!-- <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn> -->
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import { isValidURL } from '@/sdk/input.js';

export default {
    data: () => ({
        organization: null,

        submitFormTimestamp: null,

        newKeyLabel: null,
        newKeyCipherAlg: null,
        newKeyLength: null,
        // newKeyLength: '3072', // for rsa-pss
        newKeyNotBefore: null,
        newKeyNotAfter: null,
        cipherAlgChoices: [
            { text: 'HMAC-SHA256', value: 'hmac-sha256' },
            { text: 'HMAC-SHA384', value: 'hmac-sha384' },
            { text: 'HMAC-SHA512', value: 'hmac-sha512' },
            // { text: 'RSA-PSS', value: 'rsa-pss' },
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            productTypeChoices: (state) => state.productTypeChoices,
            focus: (state) => state.focus,
        }),
        isNewItemFormComplete() {
            return typeof this.newKeyLabel === 'string' && this.newKeyLabel.length > 0
            && typeof this.newKeyCipherAlg === 'string' && this.newKeyCipherAlg.length > 0
            && typeof this.newKeyLength === 'string' && this.newKeyLength.length > 0;
        },
        keyLengthChoices() {
            if (this.newKeyCipherAlg === 'hmac-sha256') {
                return [
                    { text: '256 bits', value: '256' },
                ];
            }
            if (this.newKeyCipherAlg === 'hmac-sha384') {
                return [
                    { text: '384 bits', value: '384' },
                ];
            }
            if (this.newKeyCipherAlg === 'hmac-sha512') {
                return [
                    { text: '512 bits', value: '512' },
                ];
            }
            return [{ text: 'None', value: '' }];
        },
    },
    watch: {
        focus() {
            this.$activateInput('newKeyLabelInput'); // TODO: if this is filled in already, focus on next field, etc
        },
        newKeyCipherAlg() {
            this.$nextTick(() => {
                this.newKeyLength = this.keyLengthChoices[0].value;
            });
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async createItem() {
            if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitFormTimestamp = Date.now();
            try {
                this.$store.commit('loading', { createItem: true });
                let keyLength;
                const keyLengthInput = this.newKeyLength;
                if (typeof keyLengthInput === 'string') {
                    try {
                        keyLength = Number.parseInt(keyLengthInput, 10);
                    } catch (err) {
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Key length must be a non-negative integer' }); // TODO: should be a message next to the input
                    }
                } else if (typeof keyLengthInput === 'number') {
                    keyLength = Math.round(keyLengthInput);
                }
                const item = {
                    label: this.newKeyLabel,
                    cipher_alg: this.newKeyCipherAlg,
                    key_length: keyLength,
                    not_before: this.newKeyNotBefore,
                    not_after: this.newKeyNotAfter,
                };
                const response = await this.$client.organization(this.$route.params.organizationId).webhookKey.create(item);
                if (response?.isCreated && response.id) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.$router.push({ name: 'organization-edit-webhook-key', params: { organizationId: this.$route.params.organizationId }, query: { id: response.id, t: Date.now() } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        formatDate(timestamp) {
            return new Date(timestamp).toLocaleDateString();
        },
    },
    mounted() {
        this.loadOrganization();
        this.$nextTick(() => {
            setTimeout(() => { this.$activateInput('newKeyLabelInput'); }, 1);
        });
        const todayText = new Date().toLocaleDateString();
        this.newKeyLabel = `New Key ${todayText}`;
        this.newKeyCipherAlg = 'hmac-sha256';
    },
};
</script>
